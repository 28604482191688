.main-content-wrapper {
    width:  750px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 32px;
    background-color: #36393f;
}

.input-form{
    width: 100%;
    height: 50px;
    margin-bottom: 16px;
    margin-top: 16px;
}

.input-box{
    width: 100%;
    background-color: rgba(0,0,0,0.3);
    border: none;
    height: 40px;
    border-radius: 5px;
    color: white;
    font-size: 20px;
    text-align: center;
}

.input-button{
    width: 100%;
    background-color: hsl(235, 85.6%,64.7%);
    border: none;
    height: 40px;
    border-radius: 5px;
    margin-top: 32px;
    color: white;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
}

@media screen and (max-width: 1080px) {
    .main-content-wrapper {
        width: 420px;
    }
}